import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import ContactForm from "@components/contactForm";
import Img from "gatsby-image";
import styles from '@styles/pages/contact.module.css';

const ContactPage = ({ data }) => {
    const { frontmatter, html } = data.contact.edges[0].node;
    return (
        <Layout>
            <SEO title="Contact" />
            <section className={styles.contact}>
                <div className={styles.image}>
                    <Img fluid={frontmatter.image.childImageSharp.fluid} loading="eager" alt={'Vinny Stodder smiling'} />
                </div>
                <div className={styles.fullForm}>
                    <div className={styles.cta} dangerouslySetInnerHTML={{ __html: html }}></div>

                    <div className={styles.form} >
                        <ContactForm />
                    </div>
                </div>


            </section>

        </Layout>
    )
}

export default ContactPage


export const pageQuery = graphql`
{
    contact: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/contact/"}}) {
      edges {
        node {
          html,
          frontmatter {
            image {
              childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
          }
        }
      }
    }
  
  }
`;