import React from "react"
import emailjs from 'emailjs-com';
import styles from '@styles/components/contactForm.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ContactForm = ({ data }) => {

    const apiService = process.env.GATSBY_EMAILJS_API_SERVICE;
    const template = process.env.GATSBY_EMAILJS_API_TEMPLATE
    const id = process.env.GATSBY_EMAILJS_API_ID

    function sendEmail(e) {
        e.preventDefault();


        emailjs.sendForm(apiService, template, e.target, id)
            .then((result) => {
                document.getElementById('firstName').value = '';
                document.getElementById('lastName').value = '';
                document.getElementById('email').value = '';
                document.getElementById('message').value = '';
                toast('Message successfully sent!', {
                    type: toast.TYPE.DARK,
                })
            }, (error) => {
                toast('Error sending message, please try again.', {
                    type: toast.TYPE.DARK,
                })
            });

    }

    return (
        <>
            <ToastContainer />
            <form className={styles.form} onSubmit={sendEmail}>
                <fieldset className={styles.fullName}>

                    <div className={styles.firstName}>
                        <label htmlFor="firstName">First Name</label>
                        <input type="text" name="firstName" id="firstName" required />
                    </div>

                    <div className={styles.lastName}>
                        <label htmlFor="lastName">Last Name</label>
                        <input type="text" name="lastName" id="lastName" required />
                    </div>
                </fieldset>

                <div className={styles.field}>
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" required />

                </div>

                <div className={styles.field}>
                    <label htmlFor="message">Message</label>
                    <textarea name="message" id="message" required />
                </div>

                <div className={styles.field}>
                    <input type="submit" value="Send" className={styles.button} />
                </div>

            </form>
        </>
    );
}

export default ContactForm
